import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      {/* Add the privacy policy content as paragraphs inside this div */}
      <div className="privacy-policy-content">
        <body class="c17 doc-content">
          <p class="c26">
            <span class="c28">Privacy Policy - TBVDOT</span>
          </p>
          <p class="c4 c3">
            <span class="c15 c21 c24"></span>
          </p>
          <p class="c1">
            <span class="c6">TBVDOT</span>
            <span class="c6 c15">&nbsp;by </span>
            <span class="c6">AIM Global Innovation</span>
            <span class="c6 c15">
              , We aim to comply with the Personal Data Protection Act of 2564 (
            </span>
            <span class="c7">PDPA</span>
            <span class="c0">
              ), and to be market leaders when it comes to healthcare and
              privacy.
            </span>
          </p>
          <p class="c8">
            <span class="c0">
              This policy explains how we use your personal data. We want to
              help you understand how we work with your data, so that you can
              make informed choices and be in control of your information. We
              invite you to spend a few moments understanding this policy. We
              may update this policy from time to time and, if we make any
              changes, we will notify you when we make any changes to review so.
            </span>
          </p>
          <p class="c8">
            <span class="c0">
              This policy explains how we use your personal data for our health
              services and products, including, amongst others, our private
              service, and our partner service.
            </span>
          </p>
          <p class="c8">
            <span class="c0">This policy covers:</span>
          </p>
          <p class="c8">
            <span class="c0">1. Who we are;</span>
          </p>
          <p class="c8">
            <span class="c0">
              2. What personal data we hold and how we get it;
            </span>
          </p>
          <p class="c8">
            <span class="c0">3. What we use your personal data for;</span>
          </p>
          <p class="c8">
            <span class="c0">4. Sharing your personal data;</span>
          </p>
          <p class="c8">
            <span class="c0">5. Retention;</span>
          </p>
          <p class="c8">
            <span class="c0">6. Data security and transfers; and</span>
          </p>
          <p class="c8">
            <span class="c0">7. Your rights.</span>
          </p>
          <p class="c8">
            <span class="c0">
              If you have any further questions about how we process your
              information, please don&#39;t hesitate to get in touch by
              contacting us:
            </span>
          </p>
          <p class="c8">
            <span class="c6 c15">Address: </span>
            <span class="c0">
              99/278 Mooban The Grand, Rama II, Pantai-norrasing, Samuthsakorn,
              Thailand 74000
            </span>
          </p>
          <p class="c1">
            <span class="c6 c15">Email:&nbsp;</span>
            <span class="c6">support</span>
            <span class="c6 c15">@</span>
            <span class="c6">aimglobalinnovation</span>
            <span class="c6 c15">.com</span>
          </p>
          <p class="c1 c3">
            <span class="c9 c6"></span>
          </p>
          <p class="c1 c3">
            <span class="c9 c6"></span>
          </p>
          <p class="c1 c3">
            <span class="c9 c6"></span>
          </p>
          <p class="c1 c3">
            <span class="c0"></span>
          </p>
          <p class="c4">
            <span class="c19 c15">1.&nbsp;</span>
            <span class="c5">Who we are</span>
          </p>
          <p class="c8">
            <span class="c0">
              Our health services are delivered by AIM Global Innovation. The
              registered office and principal place of business is 99/278 Mooban
              The Grand, Rama II, Pantai-norrasing, Samuthsakorn, Thailand 74000
            </span>
          </p>
          <p class="c8">
            <span class="c0">
              When this policy talks about &lsquo;TBVDOT&rsquo;,
              &lsquo;us&rsquo;, or &lsquo;we&rsquo;, it means AIM Global
              Innovation. We do NOT provide your data to other companies within
              our corporate group, including AIM Global Innovation, which
              develops and maintains our software. As we are contracted by the
              Central Chest Institute of Thailand (CCIT), an organization that
              encompasses hospitals throughout Thailand, we may share your data
              with CCIT and its affiliated hospitals to support the delivery of
              healthcare services and to improve treatment outcomes for patients
              using the TBVDOT App.
            </span>
          </p>
          <p class="c8 c3">
            <span class="c0"></span>
          </p>
          <p class="c4 c3">
            <span class="c14"></span>
          </p>
          <p class="c4 c3">
            <span class="c19 c15 c21"></span>
          </p>
          <p class="c4 c3">
            <span class="c19 c15 c21"></span>
          </p>
          <p class="c4">
            <span class="c19 c15">2.&nbsp;</span>
            <span class="c5">What personal data we hold and how we get it</span>
          </p>
          <p class="c8">
            <span class="c7">Personal details</span>
          </p>
          <p class="c1">
            <span class="c6">
              When your nurse registers you for the TBVDOT App at the hospital,
              they complete forms and provide us with basic information about
              yourself, such as your name, date of birth, phone number, email,
              and relevant medical history as a TB patient. The nurse may also
              provide us with a copy of your identification number for ID checks
              to be carried out by affiliated hospitals. Your nurse and the
              hospital are responsible for the accuracy of the information that
              they provide to us on your behalf.
            </span>
            <span class="c0">
              <br />
            </span>
          </p>
          <p class="c1">
            <span class="c7">Health information</span>
          </p>
          <p class="c8">
            <span class="c6">
              The main type of information we hold about you is health
              information related to your TB medication compliance. This
              includes your medication reminders, uploaded videos, and photos,
              as well as chat history with your nurses, which is stored on our
              server. We use this information to provide support and improve our
              app&#39;s services.
            </span>
          </p>
          <p class="c1">
            <span class="c7">Technical information and analytics</span>
          </p>
          <p class="c8">
            <span class="c0">
              When you use our App, we may automatically collect the following
              information where this is permitted by your device or browser
              settings:
            </span>
          </p>
          <ul class="c12 lst-kix_list_1-0 start">
            <li class="c10 li-bullet-0">
              <span class="c0">
                Technical information, including the address used to connect
                your mobile phone or other device to the Internet, your login
                information, system and operating system platform type and
                version, device model, browser or app version, time zone
                setting, language and location preferences, wireless carrier,
                and your location (based on IP address); and
              </span>
            </li>
            <li class="c10 li-bullet-0">
              <span class="c0">
                Information about your visit (such as when you first used the
                App and when you last used it, and the total number of sessions
                you have had on the App), including products and services you
                viewed or used, App response times and updates, interaction
                information (such as button presses or the times and frequency
                of your interactions with the communications we deliver to you
                in the App or otherwise) and any phone number used to call our
                customer service number.
              </span>
            </li>
          </ul>
          <p class="c8">
            <span class="c6">
              We work with partners who provide us with analytics services to
              help us understand how users interact with our services and
              measure the performance of our services. This includes using the
              collected data to troubleshoot issues within the App, optimize the
              user experience, and improve medical informatics. We do not use
              this information for advertising purposes, nor do we share it with
              third-party advertisers.
            </span>
          </p>
          <p class="c1">
            <span class="c7">
              Information obtained from third party services
            </span>
          </p>
          <p class="c8">
            <span class="c6">
              You may choose to connect your existing accounts with other
              providers (such as a social media provider), for example, when
              signing up to make it easier to create an account with us. If you
              choose to do this, we will receive limited information about you
              from that provider, such as your email address and name. Provided
              we are acting in accordance with data protection laws, we may also
              use information from other sources, such as specialist companies
              that supply information, online media channels, our commercial
              partners, and public registers. This information can, for example,
              help us improve and measure the effectiveness of our services.
            </span>
          </p>
          <p class="c4 c3">
            <span class="c14"></span>
          </p>
          <p class="c4">
            <span class="c15 c23">3.&nbsp;</span>
            <span class="c5">What we use your personal data for</span>
          </p>
          <p class="c8">
            <span class="c0">
              The purposes for which we use your personal data and the legal
              grounds on which we do so are as follows:
            </span>
          </p>
          <p class="c1">
            <span class="c7 c21">Providing you a service</span>
          </p>
          <p class="c1 c3">
            <span class="c0"></span>
          </p>
          <ul class="c12 lst-kix_list_3-0 start">
            <li class="c1 c27 li-bullet-0">
              <span class="c0">
                We use your health information related to your TB medication
                compliance to support you in managing your treatment plan and to
                improve our app&#39;s services.
              </span>
            </li>
          </ul>
          <p class="c20 c3">
            <span class="c0"></span>
          </p>
          <p class="c20 c3">
            <span class="c0"></span>
          </p>
          <p class="c1">
            <span class="c7 c21">Making health accessible</span>
          </p>
          <p class="c1 c3">
            <span class="c0"></span>
          </p>
          <ul class="c12 lst-kix_list_4-0 start">
            <li class="c2 li-bullet-0">
              <span class="c0">
                We use anonymized and aggregated data to enhance our services
                and software, as well as to support research and development
                efforts in the healthcare sector.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                The anonymously collected information will be used for further
                research purposes, system development and others.
              </span>
            </li>
          </ul>
          <p class="c1 c3">
            <span class="c7 c18"></span>
          </p>
          <p class="c1 c3">
            <span class="c7 c18"></span>
          </p>
          <p class="c1">
            <span class="c7 c18">Other uses</span>
          </p>
          <p class="c1 c3">
            <span class="c0"></span>
          </p>
          <ul class="c12 lst-kix_list_5-0 start">
            <li class="c10 li-bullet-0">
              <span class="c6">
                Based on our legitimate interest in managing and planning our
                business, we may analyze data about your use of our products and
                services to troubleshoot bugs within the App or our website,
                forecast demand for service, and understand
              </span>
              <span class="c6 c15">&nbsp;</span>
              <span class="c0">
                other trends in use, including which features users use the most
                and find most helpful, and what features users require from us.
                This does not involve making any decisions about you that would
                have a significant legal effect on you &ndash; it is only about
                improving our App so that we can deliver better services to you.
                Strict confidentiality and data security provisions will apply
                at all times.
              </span>
            </li>
            <li class="c10 li-bullet-0">
              <span class="c0">
                Where necessary, we may need to share personal and financial
                details for the purposes of fraud prevention and detection.
              </span>
            </li>
            <li class="c10 li-bullet-0">
              <span class="c0">
                We also store your information related to your TB medication
                compliance, chat history with your nurses, and other health
                information to make disclosures in compliance with reasonable
                requests by regulators or as otherwise required by law or
                regulation.
              </span>
            </li>
            <li class="c10 li-bullet-0">
              <span class="c0">
                Where necessary for safety, regulatory, and/or compliance
                purposes, we may audit consultations and your other interactions
                with our services. Strict confidentiality and data security
                provisions will apply at all times to any such audit and access.
              </span>
            </li>
          </ul>
          <p class="c3 c20">
            <span class="c0"></span>
          </p>
          <p class="c4 c3">
            <span class="c19 c15 c21"></span>
          </p>
          <p class="c4">
            <span class="c19 c15">4.&nbsp;</span>
            <span class="c5">Sharing your personal data with others</span>
          </p>
          <ul class="c12 lst-kix_list_6-0 start">
            <li class="c2 li-bullet-0">
              <span class="c0">
                We may share your personal data with members of our corporate
                group and our partners (such as AIM Global Innovation and other
                affiliated companies, as well as the Central Chest Institute of
                Thailand and its affiliated hospitals). This is to help us
                deliver our services to you and enhance the treatment outcomes
                for patients using the TBVDOT App.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                We may share your personal data with companies we have hired to
                provide services on our behalf, including those who act as data
                processors on our behalf, acting strictly under contract. Those
                data processors are bound by strict confidentiality and data
                security provisions, and they can only use your data in the ways
                specified by us, such as for troubleshooting, optimizing user
                experience, and improving medical informatics.
              </span>
            </li>
          </ul>
          <p class="c3 c16">
            <span class="c0"></span>
          </p>
          <p class="c1">
            <span class="c7 c21">Anonymised information</span>
          </p>
          <p class="c1 c3">
            <span class="c0"></span>
          </p>
          <ul class="c12 lst-kix_list_7-0 start">
            <li class="c10 li-bullet-0">
              <span class="c6">
                We may display on our website or share with our partners,
                including the Central Chest Institute of Thailand and affiliated
                hospitals, aggregated and anonymized data that does not
                personally identify you, but which shows general trends. For
                example, we may share the number of users of our TBVDOT App,
                medication compliance rates, and other relevant statistics that
                can help improve healthcare services and outcomes for TB
                patients without revealing any individual&#39;s personal
                information.
              </span>
            </li>
          </ul>
          <p class="c1 c3">
            <span class="c0"></span>
          </p>
          <p class="c1 c3">
            <span class="c0"></span>
          </p>
          <p class="c4">
            <span class="c19 c15">5.&nbsp;</span>
            <span class="c5">Retention periods</span>
          </p>
          <p class="c8">
            <span class="c6">
              We do not store your medical data ourselves. Instead, the data is
              transmitted to the hospitals responsible for your care, and each
              hospital will have its own policy for data retention. For example,
              if you are under the care of a hospital affiliated with the
              Central Chest Institute of Thailand (CCIT), you can refer to their
              data retention policy at https://www.ccit.go.th/pdpa/. We
              encourage you to review the data retention policies of the
              respective hospitals handling your data to understand how long
              your medical records will be retained and the procedures in place
              to safeguard your personal information.
            </span>
          </p>
          <p class="c4 c3">
            <span class="c14"></span>
          </p>
          <p class="c4">
            <span class="c19 c15">6.&nbsp;</span>
            <span class="c5">Data storage, security and transfers</span>
          </p>
          <p class="c8">
            <span class="c0">
              We do not store your health information on your mobile device or
              our servers. We transmit all your health information to the
              hospitals responsible for your care, and each hospital will have
              its own policy for data storage and security.
            </span>
          </p>
          <p class="c8">
            <span class="c0">
              Where you have chosen a password that enables you to access
              certain parts of our App, you are responsible for keeping this
              password confidential. We ask you not to share the password with
              anyone.
            </span>
          </p>
          <p class="c8">
            <span class="c0">
              We encrypt data transmitted to and from the App. Once the
              hospitals have received your information, they are responsible for
              using strict procedures and security features to try to prevent
              unauthorized access. We recommend reviewing the data protection
              policies of the respective hospitals to understand how your data
              is treated securely and in accordance with their privacy policies.
            </span>
          </p>
          <p class="c4 c3">
            <span class="c0"></span>
          </p>
          <p class="c4">
            <span class="c19">7. </span>
            <span class="c19 c25">Business transfer</span>
          </p>
          <p class="c8">
            <span class="c0">
              The company may disclose information, including your personal
              information, in the event of an organizational restructuring,
              merger, divestiture, or other transfers of assets. The receiving
              party must treat your information in a manner consistent with this
              policy and in accordance with applicable personal data protection
              laws.
            </span>
          </p>
          <p class="c4">
            <span class="c19">8. </span>
            <span class="c19 c25">Notification of personal data breach</span>
          </p>
          <p class="c8">
            <span class="c0">
              In the event that a breach of your personal data occurs, the
              Company shall notify the Office of the Personal Data Protection
              Commission without delay, within 72 (seventy-two) hours from the
              date of knowledge of the incident, as far as possible. Where the
              violation has a high risk of affecting your rights and freedoms,
              the Company and/or your hosptials will notify you of such
              infringement without delay, together with remedies, through
              various channels such as notification windows on the application,
              website, text message (SMS), email, telephone, postal mail, etc.
            </span>
          </p>
          <p class="c4">
            <span class="c19">9. </span>
            <span class="c19 c25">Amendments to Privacy Policy</span>
          </p>
          <p class="c8">
            <span class="c0">
              The Company reserves the right to amend this policy from time to
              time. You can check the changes in the Company&#39;s privacy
              policy on the notification page within the Company&#39;s
              application.
            </span>
          </p>
          <p class="c8">
            <span class="c0">
              This policy was last revised and is effective from May 10, 2023.
            </span>
          </p>
          <p class="c8 c3">
            <span class="c0"></span>
          </p>
          <p class="c8 c3">
            <span class="c0"></span>
          </p>
          <p class="c4 c3">
            <span class="c19 c15 c21"></span>
          </p>
          <p class="c4">
            <span class="c19">10</span>
            <span class="c15 c19">.&nbsp;</span>
            <span class="c5">Your rights</span>
          </p>
          <p class="c8">
            <span class="c6">
              As indicated above, whenever we rely on your consent to process
              your personal data, you have the right to withdraw your consent at
              any time. Since the TBVDOT app does not have privacy settings, you
              should contact the relevant authority, such as the hospital staff
              or nurses, for these matters.
            </span>
          </p>
          <p class="c8">
            <span class="c6 c15">
              You also have specific rights under the PDPA to:
            </span>
          </p>
          <ul class="c12 lst-kix_list_8-0 start">
            <li class="c2 li-bullet-0">
              <span class="c0">
                wherever we process data based on your consent, withdraw that
                consent at any time by contacting the hospital staff or nurses
                responsible for your care;
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                understand and request a copy of information we hold about you.
                Since we do not store your data, you should contact the
                respective hospital responsible for your care to access your
                medical records and other relevant information;
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                ask the hospital to rectify or erase information they hold about
                you, subject to limitations relating to their obligation to
                store medical or health records for medical diagnoses and
                treatment for prescribed periods of time;
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                ask the hospital to restrict the processing of your personal
                data or object to their processing; and
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                ask for your data to be provided on a portable basis by the
                hospital handling your data.
              </span>
            </li>
          </ul>
          <p class="c1 c3">
            <span class="c0"></span>
          </p>
          <p class="c1 c3">
            <span class="c7 c21"></span>
          </p>
          <p class="c1">
            <span class="c7">Contact us</span>
          </p>
          <p class="c1">
            <span class="c6 c15">
              For any questions or concerns, you can contact us by sending an
              email to&nbsp;
            </span>
            <span class="c6">support@aimglobalinnovation.com</span>
          </p>
          <p class="c1 c3">
            <span class="c15 c21 c22"></span>
          </p>
        </body>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
