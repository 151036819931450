import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import "./home.css";

import PrivacyPolicy from "../components/PrivacyPolicy";
import Modal from "../components/Modal";

const Home = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Function to check if the privacy policy modal should be open
    const checkForPrivacyPolicyModal = () => {
      const currentHash = window.location.hash;
      if (currentHash === '#privacy-policy') {
        setIsModalOpen(true);
      } else {
        setIsModalOpen(false);
      }
    };

    // Check on mount
    checkForPrivacyPolicyModal();

    // Add event listener for hash changes
    window.addEventListener('hashchange', checkForPrivacyPolicyModal, false);

    // Cleanup
    return () => {
      window.removeEventListener('hashchange', checkForPrivacyPolicyModal, false);
    };
  }, []);

  const toggleBurgerMenu = () => {
    const links = document.querySelector(".home-links");
    links.classList.toggle("active");
  };
  

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>AIM Global Innovation</title>
        <meta property="og:title" content="AIM Global Innovation" />
      </Helmet>
      <div className="navbar-container">
        <div className="max-width">
          <div className="home-logo">
            <img
              alt="image"
              src="/playground_assets/group%202.svg"
              className="home-image"
            />
            <h1>AIM Global Innovation</h1>
          </div>
          <div className="home-links">
            <a href="#aboutUs" className="home-link navbarLink">
              About Us
            </a>
            <a href="#software" className="home-link navbarLink">
              Software Solutions
            </a>
            <a
              href="https://www.aithaigen.in.th"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link1 navbarLink"
            >
              AIThaiGen
            </a>
            <a
              href="https://www.ninjacodingspace.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link2 navbarLink"
            >
              Ninja Coding Space
            </a>
            <a href="#privacy-policy" className="navbarLink">
              <button
                className="link-button navbarLink"
                onClick={openModal}
              >
                Privacy Policy
              </button>
            </a>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
              <PrivacyPolicy />
            </Modal>
            <a href="#contactUs" className="home-link3 button button-primary">
              Contact Us
            </a>
          </div>
          <div className="home-burger-menu navbar-burger-menu" onClick={toggleBurgerMenu}>
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
            </svg>
          </div>
        </div>
      </div>
      <div id="aboutUs" className="hero-container section-container">
        <div className="home-max-width1 max-width">
          <div className="home-content">
            <span className="home-subtitle beforeHeading">
              AIM Global innovation
            </span>
            <h1 className="home-title">
              <span>
                Transforming ideas into solutions with cutting-edge software
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="home-text03">development</span>
            </h1>
            <span className="home-description">
              <span>
                Our company specializes in creating custom software solutions
                for a wide range of clients across various industries. In
                addition to its software development services, the company also
                offers STEM (science, technology, engineering, and math)
                education and AI (artificial intelligence) education to students
                of all ages.
              </span>
              <br></br>
              <br></br>
              <span>
                The company&apos;s team of experienced software developers and
                educators work closely with clients and students to create
                innovative and effective solutions that meet their specific
                needs. Whether it&apos;s developing custom software for a
                business, teaching the principles of AI to students, or
                providing hands-on STEM education, our company is dedicated to
                helping its clients and students succeed.
              </span>
            </span>
          </div>
          <div className="home-image01">
            <div className="home-hero-image">
              <img
                alt="image"
                src="/playground_assets/welcome1-500w.png"
                className="home-image02"
              />
              <img
                alt="image"
                src="https://images.unsplash.com/photo-1582736317407-371893d9e146?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE4fHxzb2Z0d2FyZXxlbnwwfHx8fDE2NzIwNjcxNjU&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=500"
                className="home-image03"
              />
            </div>
            <img
              alt="image"
              src="/playground_assets/union-400w.png"
              className="home-graphic-top"
            />
            <img
              alt="image"
              src="/playground_assets/group%2018-1200w.png"
              className="home-image04"
            />
          </div>
        </div>
      </div>
      <div id="software" className="home-section section-container">
        <div className="home-max-width2 max-width">
          <div className="home-content1">
            <span className="home-text08">
              Empowering businesses through innovative software solutions
            </span>
            <h1 className="home-text09">
              <span className="home-text10">Software Development</span>
              <br></br>
            </h1>
            <span className="home-text12">
              We specialize in creating custom software solutions that are
              tailored to your specific business needs. Our experienced
              developers use the latest technologies to ensure that your
              software is fast, efficient, and user-friendly. Whether you need a
              standalone application or a full-scale system, we have the
              expertise to bring your vision to life. Don&apos;t let bad
              software hold you back any longer. Contact us today to see how we
              can revolutionize your business with our software development
              services.
            </span>
            <div className="home-container1">
              <a
                href="#contactUs"
                className="home-link4 button-secondary button bg-transparent"
              >
                Contact Us
              </a>
            </div>
          </div>
          <div className="home-image05">
            <img
              src="https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDh8fHByb2dyYW1taW5nfGVufDB8fHx8MTY3MjA3MTcyNA&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=600"
              alt="image"
              className="home-image06"
            />
          </div>
        </div>
      </div>
      <div className="section-container">
        <div className="home-max-width3 max-width">
          <div className="home-image07">
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1580618432485-1e08c5039909?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEzfHxuZXVyYWwlMjBuZXR3b3JrfGVufDB8fHx8MTY3MjA2NzY5OQ&amp;ixlib=rb-4.0.3&amp;q=80&amp;h=600"
              className="home-image08"
            />
          </div>
          <div className="home-content2">
            <span className="home-text13">AI from Zero To Hero</span>
            <h1 className="home-text14">AIThaiGen</h1>
            <span className="home-text15">
              Experience the power of artificial intelligence with our
              comprehensive and user-friendly AI learning platform.
            </span>
            <div className="home-container2">
              <a
                href="https://www.aithaigen.in.th"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link5 button-secondary button bg-transparent"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section2 section-container">
        <div className="home-max-width4 max-width">
          <div className="home-content3">
            <span className="home-text16">
              Unleashing the power of coding in the next generation
            </span>
            <h1 className="home-text17">
              <span className="home-text18">Ninja Coding Space</span>
              <br></br>
            </h1>
            <span className="home-text20">
              <span>
                We are a team of passionate educators who believe that every
                child has the potential to succeed in the world of technology.
                Our goal is to provide children with the skills and knowledge
                they need to become the next generation of coders, developers,
                and innovators.
              </span>
              <br></br>
              <br></br>
              <span>
                We offer a wide range of coding classes and workshops for kids
                of all ages, from beginners to advanced students. Our
                interactive and engaging programs are designed to help children
                develop problem-solving skills, creativity, and critical
                thinking, as well as an understanding of core coding concepts
                and techniques.
              </span>
            </span>
            <div className="home-container3">
              <a
                href="https://www.ninjacodingspace.com"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link6 button-secondary button bg-transparent"
              >
                See how
              </a>
            </div>
          </div>
          <div className="home-image09">
            <img
              alt="image"
              src="/playground_assets/screenshot%202022-12-26%20at%2022.30.17-500h.png"
              className="home-image10"
            />
          </div>
        </div>
      </div>
      <div id="contactUs" className="home-section3 section-container">
        <div className="home-max-width5 max-width">
          <div className="home-content4">
            <h1 className="home-text25">
              <span className="home-text26">Contact Us</span>
              <br></br>
            </h1>
            <a
              href="https://www.facebook.com/NinjaCodingSpace/"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link7"
            >
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon2">
                <path d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"></path>
              </svg>
            </a>
            <span className="home-text28">
              <span className="home-text29">AIM Global Innovation Co.Ltd.</span>
              <br className="home-text30"></br>
              <span className="home-text31">
                99/278 Mooban The Grand, Rama II, Pantai-norrasing, Samuthsakorn
              </span>
              <br className="home-text32"></br>
              <span className="home-text33">Thailand 74000</span>
              <br className="home-text34"></br>
              <span className="home-text35">Phone: +66-86-553-5395</span>
              <span className="home-text35">Email: support@aimglobalinnovation.com</span>
              <br className="home-text36"></br>
              <br></br>
            </span>
          </div>
          <div className="home-image11"></div>
        </div>
      </div>
    </div>
  );
};

export default Home;
